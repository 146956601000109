import type { FlexProps } from "../../layout/flex/Flex";

const baseGradient =
    "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.42) 48.44%, rgba(0, 0, 0, 0.42) 100%)";

const darkGradient =
    "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7637648809523809) 64%, rgba(0,0,0,0.5144651610644257) 79%, rgba(0,0,0,0) 97%, rgba(111,111,111,0) 100%);";

export const READ_MORE_EXPANDED_CLASSNAME = "storytile-readmore-expanded";

export const imageWrapperStyles: FlexProps["sx"] = {
    "& img, & svg": {
        transition: "all .45s ease-in-out",
    },
    "@media (hover: hover) and (pointer: fine) ": {
        _hover: {
            "& img": {
                transform: "scale(1.025)",
            },
            "& svg": {
                transform: "translateX(5px)",
            },
        },
    },
};

export const styles = {
    description: {
        fontSize: ["mobileBodyB1", "desktopH3", "desktopH3"],
        fontWeight: "bold",
        color: "white",
        overflow: "initial",
    },
    showButton: {
        mt: 0,
        p: {
            textDecoration: "underline",
            color: "white",
            fontSize: ["mobileBodyB2", "mobileH3", "mobileH3"],
            fontWeight: "bold",
        },
    },
    boxWrapper: {
        position: "sticky",
        bottom: "0",
        overflow: "hidden",
        minHeight: "80%",
        [`&:has(.${READ_MORE_EXPANDED_CLASSNAME})`]: {
            minHeight: "100%",
        },
    },
    gradientBox: {
        padding: "6",
        height: "100%",
        justifyContent: "end",
        backgroundImage: baseGradient,
        position: "absolute",
        left: "0",
        bottom: "0",
        right: "0",
        backgroundSize: "100% 30%",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        [`&:has(.${READ_MORE_EXPANDED_CLASSNAME})`]: {
            backgroundImage: darkGradient,
        },
    },
    textWrapper: {
        position: "absolute",
        bottom: "0",
        left: "0",
        gap: "0.2",
        p: ["3", "4", "6"],
        width: "full",
        flexDirection: "column",
        maxHeight: "100%",
        overflowY: "scroll",
        "::-webkit-scrollbar": { display: "none" },
    },
    readMore: {
        overflow: "hidden",
        [`&:has(.${READ_MORE_EXPANDED_CLASSNAME})`]: {
            overflow: "initial",
        },
    },
};
